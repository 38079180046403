<template>
  <div>
    <h1 class="title">
      商品基本信息
    </h1>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="商品名称："
        prop="name"
      >
        <el-input
          v-model="ruleForm.name"
          class="width450"
          size="mini"
          maxlength="30"
          show-word-limit
          placeholder="请输入商品名称"
          :readonly="disabled"
        />
        <div
          v-if="goodsType=='1'"
          style="color: #666;line-height: 26px"
        >
          建议：产品生产所属地+产品名称+补充说明（规格/产品优势），例：桐庐酒酿馒头香甜600g*2包
        </div>
        <div
          v-else-if="goodsType=='2'"
          style="color: #666;line-height: 26px"
        >
          例：余杭径山 抹香阁民宿 体验禅茶文化<br />建议使用：地区+名宿名称+名宿亮点+房型
        </div>
        <div
          v-else
          style="color: #666;line-height: 26px"
        >
          建议：商品名称+规格，例：梅饴馆黑糖老梅干（典藏系列）55g装*6盒
        </div>
      </el-form-item>
      <el-form-item
        label="商品卖点："
        prop="trait"
      >
        <el-input
          v-model="ruleForm.trait"
          class="width450"
          type="textarea"
          size="mini"
          maxlength="30"
          show-word-limit
          placeholder="请输入商品卖点"
          :readonly="disabled"
        />
        <div
          v-if="goodsType!='2'"
          style="color: #666;line-height: 26px"
        >
          例：纯天然无污染，爽脆可口
        </div>
        <div
          v-if="goodsType=='2'"
          style="color: #666;line-height: 26px"
        >
          例：超大落地窗 江景 巨屏投影
        </div>
      </el-form-item>

      <el-form-item
        required
        label="商品封面图："
      >
        <UpImage
          :disabled="disabled"
          :image-list.sync="ruleForm.coverImageList"
          :num="1"
        />
        <div>营销位显示图片，建议突出商品卖点。建议：800px*800px，2M以内。</div>
      </el-form-item>
      <el-form-item
        required
        label="商品图片："
      >
        <UpImage
          :disabled="disabled"
          :image-list.sync="ruleForm.imageUrlList"
          :num="10"
        />
        <div>
          <div v-if="+goodsType === 2">
            建议：720px*480px，2M以内；图片可拖动排序，限10张。
          </div>
          <div v-else>
            建议：800px*800px，2M以内；图片可拖动排序，限10张。
          </div>
        </div>
      </el-form-item>
      <el-form-item label="视频上传：">
        <UploadVideoOss
          :disabled="disabled"
          :url="ruleForm.videoUrl"
          @success="changeVideo"
        />
        <div>
          <div v-if="+goodsType === 2">
            建议：清晰度720p以上，画面比例3:2，3分钟以内，300M以内。
          </div>
          <div v-else>
            建议：清晰度720p以上，画面比例1:1，3分钟以内，300M以内。
          </div>
        </div>
      </el-form-item>

      <el-form-item
        v-if="isNeedAppendShop()"
        required
        label="关联门店："
      >
        <ChangeHomeShop
          :disabled="disabled"
          :shop-list-dto="shopList"
          @changeHomeShop="changeHomeShop"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import CutImgUp from '@/components/common/cutImgUp';
import UploadVideoOss from '@/components/common/uploadVideoOss/index.vue';
import MapModel from '@/components/common/mapModel.vue';
import UpImage from '@/components/common/upImage/index';
import LoadImgOssUp from '@/components/common/loadImgOssUp';
import ChangeHomeShop from './components/changeHomeShop';

export default {
  components: {
    CutImgUp,
    MapModel,
    UploadVideoOss,
    UpImage,
    LoadImgOssUp,
    ChangeHomeShop,
  },
  props: ['disabled', 'tradeGoodsDto', 'tradeShopGoodsRelationDtos'],
  data() {
    this.projectCode = localStorage.getItem('projectCode');
    return {
      ruleForm: {
        name: '',
        trait: '',
        coverImageList: [], // 封面
        imageUrlList: [], // 商品图片
        videoUrl: '',
        address: '',
        longitude: '',
        latitude: '',
      },
      shopList: [],
      rules: {
        name: [
          { required: true, message: '请输入商品名称', trigger: 'change' },
        ],
        trait: [
          { required: true, message: '请输入商品卖点', trigger: 'change' },
        ],
        address: [{ required: true, message: '请输入地址', trigger: 'change' }],
        longitude: [
          { required: true, message: '请输入经度', trigger: 'change' },
        ],
        latitude: [
          { required: true, message: '请输入纬度', trigger: 'change' },
        ],
      },
      pageType: this.$route.query.type, // 页面类型
      goodsType: this.$route.query.goodsType, // 类型
    };
  },
  watch: {
    tradeGoodsDto(row) {
      if (this.pageType == 'edit' || this.pageType == 'show') {
        this.$nextTick(() => {
          this.ruleForm = {
            ...row,
            imageUrlList: row.imageUrls ? row.imageUrls.split(',') : [],
            coverImageList: row.coverImgUrl ? [row.coverImgUrl] : [],
          };
        }, 50);
      }
    },
    tradeShopGoodsRelationDtos(row) {
      if (row) {
        this.shopList = row;
      }
    },
  },
  methods: {
    getMapData(row) {
      this.ruleForm.address = row.name;
      this.ruleForm.longitude = row.lng;
      this.ruleForm.latitude = row.lat;
    },
    changeHomeShop(list) {
      this.shopList = list.map((item) => {
        return {
          name: item.name,
          shopId: item.id,
          goodsId: this.$route.query.goodsId ? this.$route.query.goodsId : '',
        };
      });
      // this.ruleForm.shopId = item.id;
      // this.ruleForm.shopName = item.name;
    },
    getForm() {
      // 完整验证
      return new Promise((resolve) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (this.ruleForm.coverImageList.length === 0) {
              this.$message({
                type: 'error',
                message: '请上传商品封面图片（商品基本信息）',
              });
              return;
            }
            if (this.ruleForm.imageUrlList.length === 0) {
              this.$message({
                type: 'error',
                message: '请上传商品图片（商品基本信息）',
              });
              return;
            }
            if (!this.shopList.length && this.isNeedAppendShop()) {
              this.$message({
                type: 'error',
                message: '请关联门店（商品基本信息）',
              });
              return;
            }
            resolve({
              baseResolve: {
                ...this.ruleForm,
                imageUrls: this.ruleForm.imageUrlList.join(','),
                coverImgUrl: this.ruleForm.coverImageList[0],
                ancestryCategoryId: this.$route.query.goodsType,
                backendCategoryId: this.$route.query.backendCategoryId,
                storeId: this.$route.query.storeId,
                goodsType: this.$route.query.goodsBuyType, // 是否组合商品
              },
              shopResolve: this.shopList,
            });
          } else {
            if (!this.ruleForm.name) {
              this.$message({
                type: 'error',
                message: '请输入商品名称（商品基本信息）',
              });
              return;
            }
            if (!this.ruleForm.trait) {
              this.$message({
                type: 'error',
                message: '请输入商品卖点（商品基本信息）',
              });
            }
          }
        });
      });
    },
    // 视屏
    changeVideo(e) {
      this.ruleForm.videoUrl = e;
      console.log(this.ruleForm.videoUrl, 99999);
    },
    // 是否需要关联门店
    isNeedAppendShop() {
      return [2, 5, 6, 9].includes(+this.goodsType);
    },
  },
};
</script>

<style lang="scss">
.video-wrap {
  padding-left: 0px;
  .video-box {
    overflow: hidden;
    padding: 0;
    margin: 0;
    .mvGo {
      width: 100px;
      height: 100px;
      float: left;
      border: 1px dashed #c0ccda;
      position: relative;
      text-align: center;
      line-height: 100px;
      border-radius: 4px;
      list-style-type: none;
      input[type="file"] {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
      }
    }
    .haveVd {
      width: 100px;
      height: 100px;
      border: 1px solid #c0ccda;
      position: relative;
      text-align: center;
      line-height: 100px;
      border-radius: 4px;
      background: #898989;
      overflow: hidden;
      list-style-type: none;
      float: left;
      span {
        color: #fff;
      }
      button {
        position: absolute;
        bottom: 0;
        left: 0;
        background: #bfcbd9;
        cursor: pointer;
        height: 20px;
        width: 100%;
        color: #fff;
        z-index: 10;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
